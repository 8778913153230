<template>
  <svg width="220" height="135" viewBox="0 0 1163 135" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.4066 5.975C36.1733 9.09584 26.3466 24.2542 17.4133 39.8583L0.439941 67.5L20.0933 100.938L39.2999 134.375H81.7333H124.167L143.373 101.829L163.027 69.7292L152.753 51.8958C147.393 42.0875 138.013 26.4833 132.207 17.1208L121.933 0.625002H82.18C55.8266 0.625002 41.0866 2.40834 38.4066 5.975ZM133.1 40.75L149.18 67.5L133.1 96.4792L116.573 125.458H82.18H47.7866L31.2599 96.925L14.2866 68.3917L30.8133 41.1958L46.8933 14H81.7333H117.02L133.1 40.75Z"
      fill="black"
    />
    <path
      d="M66.5466 64.8251C54.04 86.2251 52.2533 94.2501 59.4 94.2501C62.08 94.2501 67.44 87.1168 71.46 78.6459C75.48 70.1751 80.3933 63.0418 81.7333 63.0418C83.0733 63.0418 87.9866 70.1751 92.0066 78.6459C99.1533 93.3584 108.533 99.6001 108.533 89.3459C108.533 82.6584 85.7533 45.2084 81.7333 45.2084C79.9466 45.2084 72.8 54.1251 66.5466 64.8251Z"
      fill="black"
    />
    <path
      d="M221.54 34.9542C220.2 39.4125 213.053 56.3542 206.8 72.85C200.1 89.3458 195.633 104.058 196.973 105.396C201.887 109.854 206.353 107.625 209.033 98.7083C211.267 92.0208 215.733 89.7917 229.133 89.7917C242.533 89.7917 247 92.0208 249.233 98.7083C250.573 103.613 255.04 107.625 258.613 107.625C266.653 107.625 265.76 103.613 247.447 59.9208C234.94 28.7125 226.9 21.1333 221.54 34.9542ZM234.94 57.2458C235.833 61.7042 238.513 68.8375 240.3 72.85C242.98 79.5375 241.193 80.875 229.133 80.875C217.073 80.875 215.287 79.5375 217.967 72.85C219.753 68.8375 222.433 61.7042 223.327 57.2458C224.667 53.2333 227.347 49.6667 229.133 49.6667C230.92 49.6667 233.6 53.2333 234.94 57.2458Z"
      fill="black"
    />
    <path
      d="M278.267 67.0542C278.267 101.383 279.16 107.625 285.413 107.625C290.773 107.625 292.113 104.504 290.773 96.4792C288.54 86.225 297.473 66.6083 301.493 71.9583C302.833 73.2958 309.087 81.7667 315.787 90.6833C328.293 107.625 336.333 112.083 336.333 102.721C336.333 100.046 330.08 90.2375 322.933 80.875L309.533 63.9333L323.38 50.1125C330.973 42.0875 335.44 34.0625 333.653 31.3875C331.42 27.8208 323.827 33.1708 310.873 46.5458C300.153 58.1375 290.327 67.5 288.987 67.5C288.093 67.5 287.647 59.0292 288.093 49.2208C288.987 37.1833 287.2 30.05 284.073 28.7125C279.607 26.9292 278.267 36.7375 278.267 67.0542Z"
      fill="black"
    />
    <path
      d="M352.86 30.4958C351.073 31.8333 349.733 50.1125 349.733 70.6208V107.625H376.533C396.187 107.625 403.333 105.842 403.333 101.383C403.333 96.925 398.42 96.0333 383.233 97.8167C363.133 100.046 363.133 99.6 363.133 85.7792C363.133 72.4042 363.58 71.9583 383.233 71.9583C394.4 71.9583 403.333 69.7292 403.333 67.5C403.333 64.825 394.4 63.0417 383.233 63.0417C364.92 63.0417 363.133 62.15 363.133 51.8958C363.133 41.6417 364.92 40.75 383.233 40.75C397.973 40.75 403.333 38.9667 403.333 34.0625C403.333 29.1583 397.527 27.375 379.66 27.375C366.26 27.375 354.2 28.7125 352.86 30.4958Z"
      fill="black"
    />
    <path
      d="M425.667 27.8208C422.54 63.0417 424.327 107.625 428.793 107.625C431.473 107.625 434.6 100.046 435.493 90.6833C437.28 68.8375 450.233 68.3917 464.973 89.7917C470.78 98.2625 477.033 105.842 479.713 106.733C486.413 109.408 484.18 97.3708 476.14 85.7792C469.44 75.9708 469.44 74.6333 477.033 69.2833C485.52 63.0417 487.753 42.9792 480.16 35.4C475.693 30.9417 425.667 24.2542 425.667 27.8208ZM472.567 51.8958C472.567 59.0292 468.993 61.2583 453.807 62.15C435.94 63.4875 434.6 63.0417 434.6 51.8958C434.6 40.75 435.94 40.3042 453.807 41.6417C468.993 42.5333 472.567 44.7625 472.567 51.8958Z"
      fill="black"
    />
    <path
      d="M537.333 67.5V107.625H559.22C582.893 107.625 595.4 100.046 595.4 85.7792C595.4 81.3208 592.273 75.0792 588.7 71.9583C583.34 67.5 583.34 65.7167 588.253 60.3667C603.44 45.2083 584.233 27.375 552.52 27.375H537.333V67.5ZM579.767 51.8958C579.767 59.0292 576.193 61.2583 563.24 62.15C547.607 63.4875 546.267 62.5958 546.267 51.8958C546.267 41.1958 547.607 40.3042 563.24 41.6417C576.193 42.5333 579.767 44.7625 579.767 51.8958ZM579.767 85.3333C579.767 95.1417 577.98 96.4792 564.133 96.4792C550.287 96.4792 548.5 95.1417 547.16 83.9958C545.82 71.9583 546.267 71.5125 562.793 72.85C577.533 74.1875 579.767 75.525 579.767 85.3333Z"
      fill="black"
    />
    <path
      d="M613.267 67.0542C613.267 96.925 614.607 107.625 619.073 107.625C626.667 107.625 626.22 30.9417 618.627 28.2667C614.607 26.9292 613.267 38.5208 613.267 67.0542Z"
      fill="black"
    />
    <path
      d="M656.593 36.2916C642.747 47.4374 638.28 63.9333 644.533 83.5499C657.933 123.229 716 112.083 716 69.7291C716 57.6916 712.427 50.1124 703.047 40.3041C687.86 25.1458 672.673 23.8083 656.593 36.2916ZM697.24 46.0999C711.533 60.3666 698.58 98.7083 679.373 98.7083C658.38 98.7083 644.98 63.0416 660.613 47.8833C668.653 39.4124 689.647 38.5208 697.24 46.0999Z"
      fill="black"
    />
    <path
      d="M733.867 67.0542C733.867 97.8167 735.207 107.625 739.673 107.625C744.14 107.625 745.033 100.938 743.693 79.5375C741.46 47.4375 746.373 48.775 758.88 84.4417C769.153 113.867 777.64 113.421 790.147 81.7667C803.547 48.3292 806.673 47.8833 803.993 79.5375C802.207 101.829 803.1 107.625 808.013 107.625C812.927 107.625 814.267 99.1542 814.267 67.0542C814.267 32.2792 813.373 26.9292 807.12 28.2667C802.653 29.1583 795.06 41.6417 787.913 59.475C781.66 76.4167 775.407 89.7917 774.067 89.7917C772.727 89.7917 766.473 76.4167 760.22 59.475C753.073 41.6417 745.48 29.1583 741.013 28.2667C734.76 26.9292 733.867 32.2792 733.867 67.0542Z"
      fill="black"
    />
    <path
      d="M857.147 30.9417C854.913 34.9542 827.667 104.058 827.667 106.287C827.667 107.179 830.347 107.625 833.92 107.625C837.493 107.625 841.96 103.612 843.3 98.7083C845.533 92.0208 850 89.7917 863.4 89.7917C876.8 89.7917 881.267 92.0208 883.5 98.7083C887.073 109.854 894.667 110.3 894.22 99.6C894.22 88.0083 869.653 30.05 864.293 28.2667C862.06 27.8208 858.487 28.7125 857.147 30.9417ZM869.207 57.2458C870.1 61.7042 872.78 68.8375 874.567 72.85C877.247 79.5375 875.46 80.875 863.4 80.875C851.34 80.875 849.553 79.5375 852.233 72.85C854.02 68.8375 856.7 61.7042 857.593 57.2458C858.933 53.2333 861.613 49.6667 863.4 49.6667C865.187 49.6667 867.867 53.2333 869.207 57.2458Z"
      fill="black"
    />
    <path
      d="M912.533 67.0542C912.533 99.1542 913.873 107.625 918.787 107.625C923.7 107.625 924.593 103.612 922.807 92.0208C917.893 62.15 942.46 68.3917 961.22 101.829C963.453 106.287 967.027 108.071 969.26 106.287C971.047 104.058 969.26 96.4792 965.24 89.3458C957.647 76.8625 957.647 75.9708 966.133 67.5C985.34 48.3292 972.833 31.3875 937.547 28.2667L912.533 26.4833V67.0542ZM959.433 51.8958C959.433 59.0292 955.86 61.2583 940.673 62.15C922.807 63.4875 921.467 63.0417 921.467 51.8958C921.467 40.75 922.807 40.3042 940.673 41.6417C955.86 42.5333 959.433 44.7625 959.433 51.8958Z"
      fill="black"
    />
    <path
      d="M991.593 30.4958C989.807 31.8333 988.467 50.1125 988.467 70.6208C988.467 100.492 989.807 107.625 995.167 107.625C1000.53 107.625 1001.87 100.046 1001.87 67.5C1001.87 30.4958 999.633 22.4708 991.593 30.4958Z"
      fill="black"
    />
    <path
      d="M1024.2 67.5C1024.2 101.383 1025.09 107.625 1031.35 107.625C1037.6 107.625 1038.49 103.167 1036.71 78.6458C1035.81 62.5958 1036.26 49.6667 1037.6 49.6667C1038.94 49.6667 1047.43 63.0417 1056.81 78.6458C1080.93 119.663 1086.73 117.433 1086.73 67.0542C1086.73 38.075 1085.39 26.9292 1081.37 28.2667C1077.8 29.6042 1076.46 39.4125 1077.35 57.6917C1077.8 72.85 1076.91 85.3333 1074.67 85.3333C1072.89 85.3333 1063.51 71.9583 1054.13 56.3542C1030.01 15.3375 1024.2 17.5667 1024.2 67.5Z"
      fill="black"
    />
    <path
      d="M1112.19 30.4958C1110.41 31.8333 1109.07 50.1125 1109.07 70.6208V107.625H1135.87C1155.52 107.625 1162.67 105.842 1162.67 101.383C1162.67 96.925 1157.75 96.0333 1142.57 97.8167C1122.47 100.046 1122.47 99.6 1122.47 85.7792C1122.47 72.4042 1122.91 71.9583 1142.57 71.9583C1153.73 71.9583 1162.67 69.7292 1162.67 67.5C1162.67 64.825 1153.73 63.0417 1142.57 63.0417C1124.25 63.0417 1122.47 62.15 1122.47 51.8958C1122.47 41.6417 1124.25 40.75 1142.57 40.75C1157.31 40.75 1162.67 38.9667 1162.67 34.0625C1162.67 29.1583 1156.86 27.375 1138.99 27.375C1125.59 27.375 1113.53 28.7125 1112.19 30.4958Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'MarineLogo',
};
</script>
